<template>
  <div class="box">
    <div class="columns is-mobile">
      <div class="column">
        <p class="heading">
          Block Producer
        </p>
        <!-- <div class="levels">
          <div class="level-left">
            <div class="level-item">
              <figure class="image is-48x48">
                <img class="is-rounded" :src="src">
              </figure>
            </div>
            <div class="level-item">
              {{ name }}<br />
              {{ id | shortEth }}
            </div>
          </div>
        </div> -->
      </div>
      <div class="column is-narrow" v-if="active">
        Active
      </div>
      <div class="column is-narrow" v-else>
        Inactive
      </div>
    </div>
    <p class="heading">
      Produced this Epoch
    </p>
    <p class="content">
      <span v-if="pendingEpochBlocks == 1"><strong>{{ pendingEpochBlocks | currency }} Eden Block</strong></span>
      <span v-else><strong>{{ pendingEpochBlocks | currency }} Eden Blocks</strong></span>
    </p>
    <div v-if="confirmedBlocks && confirmedBlocks != '0'">
      <p class="heading">
        Through Last Epoch
      </p>
      <p class="content">
        <span><strong>{{ rewards | fromWei | currency }} EDEN</strong></span>
        <br />
        <span>{{ confirmedBlocks | currency }} Eden Blocks (through last Epoch)</span>
      </p>
    </div>
    <div v-if="account && isEligibleToClaim()">
      <p class="heading">
        Claimed
      </p>
      <p class="content">
        {{ totalClaimed | fromWei | currency }} EDEN
      </p>
      <p class="heading">
        Slashed
      </p>
      <p class="content">
        {{ totalSlashed | fromWei | currency }} EDEN
      </p>
      <p class="heading">
        Available to Claim
      </p>
      <p class="content">
        {{ availableToClaim | fromWei | currency }} EDEN
      </p>
      <b-button
        expanded
        type="is-success"
        size="is-large"
        :loading="isLoading"
        :disabled="!isAllowedToClaim()"
        @click="claim()"
      >
        Claim
      </b-button>
    </div>
  </div>
</template>

<script>
  import {ethers} from 'ethers';
  import {mapGetters} from 'vuex';
  import { metaDataForProducer } from '../services/NameMaps';

  export default {
    components: {},
    computed: {
      ...mapGetters({
        account: 'accountModule/account', 
        merkleProof: 'merkleProof'
      }),
      name: function () {
        return metaDataForProducer(this.id).name;
      },
      src: function () {
        return metaDataForProducer(this.id).src;
      }
    },
    data() {
      return {
        isLoading: false,
      }
    },
    props: [
      'id', 
      'active',
      'rewardCollector',
      'rewards',
      'confirmedBlocks',
      'pendingEpochBlocks',
      'totalClaimed',
      'totalSlashed',
      'availableToClaim'
    ],
    methods: {
      isAllowedToClaim() {
        let validAccount = this.isEligibleToClaim();
        let pendingBalance = this.availableToClaim.gt("0");
        return validAccount && pendingBalance;
      },
      isEligibleToClaim() {
        let validAccount = this.account.toLowerCase() == this.id.toLowerCase()
          || this.account.toLowerCase() == this.rewardCollector?.toLowerCase();
        return validAccount;
      },
      async claim() {
        this.isLoading = true;
        try {
          const payload = {
            index: this.merkleProof.balances[this.id.toLowerCase()].index,
            account: this.id,
            totalEarned: this.merkleProof.balances[this.id.toLowerCase()].amount,
            proof: this.merkleProof.balances[this.id.toLowerCase()].proof
          }
          let result = await this.$store.dispatch('claimFromDistributor', payload);
          if (result) {
            // update
            this.$store.dispatch('updateDistributorData');
            this.$store.dispatch('updateGovernanceData');
          }
        }
        catch (err) {
          console.log(err);
        }
        this.isLoading = false;
      },
      formatBalance(balance) {
        return balance ? ethers.utils.formatUnits(balance) : "0"
      },
    },
  };
</script>
