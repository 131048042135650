import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Fountain from '../views/Fountain.vue';
import Slots from '../views/Slots.vue';
import Farm from '../views/Farm.vue';
import Staking from '../views/Staking.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/farm',
        name: 'Farm',
        component: Farm
    },
    {
        path: '/staking',
        name: 'Staking',
        component: Staking
    },
    {
        path: '/fountain',
        name: 'Fountain',
        component: Fountain
    },
    {
        path: '/slots',
        name: 'Slots',
        component: Slots
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
