import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Buefy from "buefy";
import moment from 'moment';

Vue.use(Buefy);

Vue.config.productionTip = false;

Vue.filter("relativeTime", function(value) {
  return moment.utc(value * 1000).fromNow();
});

Vue.filter("explorerLinkToMinedBlocks", function (address) {
  return `https://etherscan.io/address/${address}#mine`
});

Vue.filter("explorerLinkToAddress", function (address) {
  return `https://explorer.edennetwork.io/address/${address}`
  // return `https://etherscan.io/address/${address}`
});

Vue.filter("to0Dp", function (value) {
  if (!value) return value;
  return parseFloat(value).toFixed(0);
});

Vue.filter("to2Dp", function (value) {
  if (!value) return value;
  return parseFloat(value).toFixed(2);
});

Vue.filter("to6Dp", function (value) {
  if (!value) return value;
  return parseFloat(value).toFixed(6);
});

Vue.filter("fromWei", function (value) {
  if (!value) return value;
  return parseFloat(value) / 1e18;
});

import VueCurrencyFilter from "vue-currency-filter";

Vue.use(VueCurrencyFilter, [
  { // default name 'currency'
    symbol: "",
    thousandsSeparator: ",",
    fractionCount: 0,
    fractionSeparator: ".",
    symbolPosition: "front",
    symbolSpacing: false,
  },
  {
    name: "currency_2",
    symbol: "",
    thousandsSeparator: ",",
    fractionCount: 2,
    fractionSeparator: ".",
    symbolPosition: "front",
    symbolSpacing: false,
  },
  {
    name: "currency_6",
    symbol: "",
    thousandsSeparator: ",",
    fractionCount: 6,
    fractionSeparator: ".",
    symbolPosition: "front",
    symbolSpacing: false,
  },
  {
    name: "percentage",
    symbol: "%",
    thousandsSeparator: ",",
    fractionCount: 0,
    fractionSeparator: ".",
    symbolPosition: "back",
    symbolSpacing: false,
  },
  {
    name: "percentage_2",
    symbol: "%",
    thousandsSeparator: ",",
    fractionCount: 2,
    fractionSeparator: ".",
    symbolPosition: "back",
    symbolSpacing: false,
  },
]);

Vue.filter("shortEth", function (value) {
  if (!value) return value;

  return `
  ${value.substr(0, 7)}...${value.substr(value.length - 5, value.length)}
  `;
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
