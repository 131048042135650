import CoinGecko from 'coingecko-api';

const CoinGeckoClient = new CoinGecko();

async function getPricesFromCoinGecko() {
    let coinGeckoIds = [
      'ethereum', 'eden'
    ];

    let rawData = await Promise.all(
      coinGeckoIds.map((id) => CoinGeckoClient.coins.fetch(id, {
        community_data: false,
        developer_data: false,
        localization: false
      }))
    );

    return rawData;
}

export {
    getPricesFromCoinGecko
}