<template>
    <section style="height: 100%;">
        <nav class="navbar is-transparent container" role="navigation" aria-label="main navigation">
            <div class="navbar-brand">
                <b-navbar-item href="https://www.edennetwork.io" target="_blank">
                    <img src="assets/logo.svg" alt="Eden" />
                </b-navbar-item>

                <a 
                    role="button" 
                    @click="showNav = !showNav"
                    class="navbar-burger"
                    :class="{ 'is-active': showNav }"
                    aria-label="menu" 
                    aria-expanded="false" 
                >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div class="navbar-menu" :class="{ 'is-active': showNav }">
                <div class="navbar-start">
                    <b-navbar-item tag="router-link" :to="{ name: 'Staking' }">
                        <strong>Stake EDEN</strong>
                    </b-navbar-item>
                    <b-navbar-item tag="router-link" :to="{ name: 'Farm' }">
                        <strong>LP Rewards</strong>
                    </b-navbar-item>
                    <b-navbar-item tag="router-link" :to="{ name: 'Fountain' }">
                        <strong>Fountain</strong>
                    </b-navbar-item>
                    <b-navbar-item tag="router-link" :to="{ name: 'Slots' }">
                        <strong>Slot Auctions</strong>
                    </b-navbar-item>
                </div>

                <div class="navbar-end">
                        <b-dropdown 
                            aria-role="menu" 
                            append-to-body
                            position="is-bottom-left"
                            v-if="account && tokenBalance"
                        >
                            <template #trigger>
                                <b-navbar-item>
                                    <b-button rounded type="is-text" icon-right="menu-down">
                                        {{ tokenBalance | fromWei | currency }} EDEN
                                    </b-button>
                                </b-navbar-item>
                            </template>

                            <b-dropdown-item has-link aria-role="menuitem">
                                <a :href="sushiUrl" target="_blank">
                                    Buy on Sushi&nbsp;&nearr;
                                </a>
                            </b-dropdown-item>
                            <b-dropdown-item has-link aria-role="menuitem">
                                <a :href="bancorUrl" target="_blank">
                                    Buy on Bancor&nbsp;&nearr;
                                </a>
                            </b-dropdown-item>
                        </b-dropdown>
                    <b-navbar-item v-if="account">
                        <b-button rounded type="is-primary" tag="router-link" :to="{name: 'Home'}">
                            <span class="icon">
                                <i class="mdi mdi-ethereum"></i>
                            </span>
                            <span>
                                {{ account | shortEth }}
                            </span>
                        </b-button>
                    </b-navbar-item>
                    <b-navbar-item v-else>
                        <b-button rounded type="is-primary" @click="connect">
                            <span class="icon">
                                <i class="mdi mdi-ethereum"></i>
                            </span>
                            <span>
                                Connect
                            </span>
                        </b-button>
                    </b-navbar-item>
                </div>
            </div>
        </nav>

        <router-view></router-view>

        <footer class="footer">
            <div class="content has-text-centered">
                <p>&nbsp;</p>
            </div>
        </footer>

        <nav class="navbar is-fixed-bottom is-transparent is-hidden-mobile">
            <div class="container py-3 is-flex is-justify-between">
                <div class="navbar-brand">
                    <b-navbar-item href="https://www.edennetwork.io" target="_blank">
                        <img src="assets/logo.svg" alt="Eden" />
                    </b-navbar-item>
                </div>
                <div class="navbar-end">
                    <b-navbar-item href="https://github.com/eden-network" target="_blank">
                        <span class="icon is-medium">
                            <i class="mdi mdi-github"></i>
                        </span>
                        Github
                    </b-navbar-item>
                    <b-navbar-item href="https://twitter.com/edennetwork" target="_blank">
                        <span class="icon is-medium">
                            <i class="mdi mdi-twitter"></i>
                        </span>
                        Twitter
                    </b-navbar-item>
                    <b-navbar-item href="https://discord.io/edennetwork" target="_blank">
                        <span class="icon is-medium">
                            <i class="mdi mdi-discord"></i>
                        </span>
                        Discord
                    </b-navbar-item>
                </div>
            </div>
        </nav>
    </section>
</template>

<script>
  import {mapGetters} from 'vuex';
  import { BigNumber } from 'ethers';

  export default {
    components: {},
    computed: {
        ...mapGetters({
            account: 'accountModule/account',
            contracts: 'contracts',
            tokenBalances: 'tokenBalances',
        }),
        sushiUrl: function () {
            try {
                const edenAddress = this.contracts.tokenContract.address;
                const baseUrl = "https://app.sushi.com/swap/ETH/"
                return baseUrl + edenAddress;
            }
            catch {
                return "https://app.sushi.com/swap"
            }
        },
        bancorUrl: function () {
            try {
                const edenAddress = this.contracts.tokenContract.address
                const baseUrl = "https://app.bancor.network/?from=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&to="
                return baseUrl + edenAddress;
            }
            catch {
                return "https://app.bancor.network"
            }
        },
        tokenBalance: function () {
            try {
                const edenAddress = this.contracts.tokenContract.address;
                return this.tokenBalances[edenAddress];
            }
            catch {
                return BigNumber.from("0");
            }
        },
    },
    methods: {
      connect() {
        this.$store.dispatch("bootstrap", {
          onSuccessCallback: () => {},
        });
      },
    },
    data() {
        return {
            showNav: false
        }
    },
    mounted() {
    //   if (
    //       this.$router.currentRoute.path === '/fountain' || 
    //       this.$router.currentRoute.path === '/staking' ||
    //       this.$router.currentRoute.path === '/farm' ||
    //       this.$router.currentRoute.path === '/slots'
    //     ) {
    //     this.$router.push({name: 'Home'});
    //   }
    },
    beforeMount() {
        this.$store.dispatch("handleFirstUpdate");
    }
  };
</script>

<style lang="scss">

    .hero-image-holder ~ .hero {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        z-index: 2;
    }

    .hero-body {
        z-index: 2;
    }

    .hero-image-holder {
        background: black;
        position: relative;
        display: block;
        height: 300px;
        width: 100%;
        overflow: hidden;
    }
    .hero-img { position:absolute; top:0; left:50%; max-width: 1700px; transform: translateX(-50%); object-fit: cover; object-position: center center; z-index:1; width:100%; height:100%; }
	.hero-img { object-position: 63% center; }
	.hero-img.fore { left: 0%; transform: translate(0%); object-position: 10% 80%; }
    .hero-img.back { max-width: 100%; }
    .hero-img.slots { left:80%; width: 50%; object-position: left center; max-width: none; }
    .hero-img.farm { width: 60%; left: 80%; object-position: center 20%; }
	.hero-img.fountain { object-fit: contain; left: 0%; transform: translate(0%); object-position: 80% center; }
    // .hero-img.farm { width: 50%; object-position: right center; transform: translateX(0%); }

    .sweep-left-blue { 
        background:url("/assets/sweep-left-blue.svg") no-repeat left top;
        background-size:100% 50px;
        display:block;
        width:100%;
        height:50px;
        position:relative;
        left:0;
        z-index:9;
    }

    .button {
        font-weight: 600;
    }

    .button.is-primary {
        background: linear-gradient(to right top, #25d37e, #CAFF00) !important;
        color: #171C47 !important;
    }

    .button.is-primary:hover {
        background: #fff !important;
    }

    .is-uppercase {
        text-transform: uppercase;
    }

    .b-tabs .tab-content {
        background: #2B305A;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .heading {
        font-size: 1rem !important;
        letter-spacing: 0.25px !important;
        font-weight: 600;
        color: #c5c5c5;
        text-transform: none !important;
    }

    .bn-onboard-custom.bn-onboard-modal {
        z-index: 20;
        font-family: "Titillium Web", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    }

    // Archer colors
    $archer-primary-cyan: #00cec9;
    $archer-primary-orange: #fdcb6e;
    $archer-secondary-red: #E17055;

    // Eden colors
    $eden-background: #171C47;
    $eden-light-blue: #2B305A;
    $eden-green: #CAFF00;
    $eden-dark-green: #25d37e;

    $white: #fff;
    $dark: $eden-background;
    $background: $eden-background;
    $footer-background-color: $eden-background;

    $family-primary: "Titillium Web", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    $family-secondary: "Titillium Web", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

    $primary: $eden-green;
    $primary-invert: $dark;
    $link: $eden-green;
    $link-hover: #c5c5c5;
    $success: $eden-green;
    $info: $archer-primary-cyan;
    $warning: $archer-primary-orange;
    $danger: $archer-secondary-red;

    $text: $white;
    $text-light: $white;
    $text-strong: $white;

    $button-text-decoration: "none";
    $button-text-hover-background-color: $eden-light-blue;
    $button-text-hover-color: $link;

    $border: $eden-light-blue;
    // $table-cell-border: 1px solid $text;
    $radius: 6px;

    $tabs-border-bottom-width: 0px;
    $tabs-link-active-color: $text;
    $tabs-link-hover-color: $link;
    $tabs-boxed-link-active-color: $text;
    $tabs-boxed-link-active-background-color: $eden-light-blue;
    $tabs-boxed-link-hover-background-color: $eden-light-blue;

    $hr-background-color: $eden-dark-green;
    $hr-margin: 0;

    $input-border-color: $eden-background;
    $input-hover-border-color: $eden-background;
    $input-focus-border-color: $text;

    $weight-normal: 500;
    $weight-semibold: 600;

    $body-size: 16px;
    $body-font-size: 0.875rem;
    $size-1: 4rem;

    $box-background-color: $eden-light-blue;
    $box-shadow: -8px 0 8px 0 #3f3f3f38, 8px 0 8x 0 #d0d0d038;

    $navbar-item-hover-background-color: transparent;
    $navbar-fixed-z: 0;
    $navbar-z: 0;

    $scheme-main: $eden-background;

    @import "~bulma/sass/utilities/_all";
    @import '~bulma';
    @import "~buefy/src/scss/buefy";
</style>
