import { request, gql } from 'graphql-request'
import { DISTRIBUTIOIN_SUBGRAPH } from '../settings';

export async function fetchData(address) {
    const query  = gql`
    {
        distributor(id: "${address.toLowerCase()}") {
            currentDistribution {
                id,
                distributionNumber,
                merkleRoot,
                metadataURI
            }
        }
    }`

    let data = await request(DISTRIBUTIOIN_SUBGRAPH, query);
    try {
        return data.distributor.currentDistribution;
    }
    catch (err) {
        console.log(err);
        throw("Could not fetch data");
    }
}