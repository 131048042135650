<template>
    <article>

        <div class="hero hero-image-holder">
          <img class="hero-img slots" src="/assets/headers/Intersection-3.jpg" alt="">
          <!-- <img class="hero-img back" src="/assets/headers/eden-hero-background.jpg" alt=""> -->
          <!-- <img class="hero-img fore" src="/assets/headers/eden-hero-foreground.png" alt=""> -->
          <div class="hero-body">
              <div class="container is-max-desktop">
                  <h1 class="title is-size-1 has-text-success">
                    Slot Auctions
                  </h1>
                  <h2 class="subtitle">
                    Permissionless, Market-Driven Auctions for Priority Blockspace
                  </h2>
                  <b-button class="is-primary" tag="a" href="https://docs.edennetwork.io/slot-tenants/getting-started" target="_blank">
                    Learn More
                  </b-button>
              </div>
          </div>
          <div class="hero-footer">
            <div class="sweep-left-blue"></div>
          </div>
        </div>

        <section v-if="slotData" class="container is-max-desktop mt-5">
          <div class="columns">
            <div class="column is-4" v-for="(slot, index) in slotData" :key="index">
              <slot-tenant
                :index="index"
                :balance="slot.balance"
                :bidAmount="slot.bidAmount"
                :bidder="slot.bidder"
                :delegate="slot.delegate"
                :expiry="slot.expiry"
                :minimumBid="slot.minimumBid"
                :periodStart="slot.periodStart"
              ></slot-tenant>
            </div>
          </div>
        </section>

        <spinner v-else></spinner>
    </article>
</template>
<script>
  import {mapGetters} from 'vuex';

  import SlotTenant from "../components/SlotTenant";
  import Spinner from "../components/Spinner";

  const POLL_RATE = 15 * 1000;
  export default {
    components: {
      SlotTenant,
      Spinner,
    },
    computed: {
      ...mapGetters([
        'slotData',
      ]),
    },
    data() {
      return {
        polling: null,
      };
    },
    mounted() {
      this.handlePollingUpdate();
      this.polling = setInterval(this.handlePollingUpdate, POLL_RATE);
    },
    beforeDestroy() {
      clearInterval(this.polling);
    },
    methods: {
      async handlePollingUpdate() {
        await this.$store.dispatch('updateSlotData');
        // await this.$store.dispatch('getStakedBalancesForUser');
        // await this.$store.dispatch('getApprovedBalancesForUser');
      }
    },
  };
</script>
