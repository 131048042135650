export function metaDataForApplication(address) {
    const defaultObject = {
        "name": "Slot Tenant",
        "src": ""
    }

    const applicationNames = {
        "0xfc7a4c74bed0d761b9dc648f8730738d1449333a": {
            "name": "Band",
            "src": "assets/applications/band.jpeg"
        },
        "0xd9e1ce17f2641f24ae83637ab66a2cca9c378b9f": {
            "name": "Sushi",
            "src": "/assets/applications/sushi.png"
        },
        "0x2f9ec37d6ccfff1cab21733bdadede11c823ccb0": {
            "name": "Bancor",
            "src": "assets/applications/bancor.png"
        }
    }

    try {
        return applicationNames[address].name
    }
    catch {}
    return null
}

export function metaDataForProducer(address) {
    const defaultObject = {
        "name": "Block Producer",
        "src": "https://bulma.io/images/placeholders/128x128.png"
    }

    const coinbaseNames = {
        "0xb3b7874f13387d44a3398d298b075b7a3505d8d4": {
            "name": "Babel Pool",
            "src": ""
        },
        "0x5a0b54d5dc17e0aadc383d2db43b0a0d3e029c4c": {
            "name": "Block Producer",
            "src": ""
        },
        "0xe206e3dca498258f1b7eec1c640b5aee7bb88fd0": {
            "name": "Block Producer",
            "src": ""
        },
        "0x00192fb10df37c9fb26829eb2cc623cd1bf599e8": {
            "name": "2Miners: PPLNS",
            "src": ""
        },
        "0x002e08000acbbae2155fab7ac01929564949070d": {
            "name": "2Miners: SOLO",
            "src": ""
        },
        "0x1ad91ee08f21be3de0ba2ba6918e714da6b45836": {
            "name": "Block Producer",
            "src": ""
        },
        "0x52bc44d5378309ee2abf1539bf71de1b7d7be3b5": {
            "name": "Nanopool",
            "src": ""
        }
    }

    return coinbaseNames[address] || defaultObject;
}