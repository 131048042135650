import { BigNumber } from 'ethers';
import { RPC_URL } from '../settings';

const EDEN_API = process.env.VUE_APP_EDEN_API

const state = () => ({
    fountain: null,
    sev: null,
    initialLoadComplete: false,
    accountState: null,
})

const getters = {
    fountain: state => state.fountain,
    sev: state => state.sev,
    sevPayloadFor: state => address => {
        try {
            return state.sev.balances[address]
        }
        catch (err) {
            // console.log("merkle/getters/sevPayloadFor", address, err)
        }
        return null
    },
    sevFor: state => address => {
        try {
            const totalEarned = BigNumber.from(state.sev.balances[address].amount)
            const { totalClaimed, totalSlashed } = state.accountState
            return totalEarned
                .sub(totalClaimed)
                .sub(totalSlashed)
        }
        catch (err) {
            // console.log("merkle/getters/sevFor", address, err)
        }
        return null
    }
}

const mutations = {
    setFountain(state, fountain) {
        state.fountain = fountain
    },
    setSev(state, sev) {
        state.sev = sev
    },
    setAccountState(state, accountState) {
        state.accountState = accountState
    }
}

const actions = {
    async loadAccountState({ commit, rootGetters }) {
        try {
            const account = rootGetters['accountModule/account']
            const { sevDistributorContract } = rootGetters['contracts']
            const accountState = await sevDistributorContract.accountState(account);
            commit('setAccountState', accountState)
        }
        catch (err) {
            console.log("merkle/actions/loadAccountState", err)
        }
    },
    async loadSev({ commit }) {
        try {
            let result = await fetch(EDEN_API + "/sev")
            let data = await result.json()
            commit('setSev', data)
        }
        catch (err) {
            console.log("merkle/actions/loadSev", err)
        }
    },
    async loadFountain({ commit }) {
        try {
            let result = await fetch(EDEN_API + "/fountain")
            let data = await result.json()
            commit('setFountain', data)
        }
        catch (err) {
            console.log("merkle/actions/loadFountain", err)
        }
    },
    async claimSev({ state, commit, getters, rootGetters }) {
        try {
            const account = rootGetters['accountModule/account']
            const { sevDistributorContract } = rootGetters['contracts']
            const { index, amount, proof } = getters.sevPayloadFor(account)
            const tx = await sevDistributorContract.claim(
                index, 
                account, 
                amount, 
                proof
            );
            const txReceipt = await tx.wait(1);
            return txReceipt.status
        }
        catch (err) {
            console.log("merkle/actions/claimSev::err", err)
        }
        return false
    },
    async claimAndStakeSev({ state, commit, getters, rootGetters }) {
        try {
            const account = rootGetters['accountModule/account']
            const { sevDistributorContract } = rootGetters['contracts']
            console.log(sevDistributorContract.address)
            const { index, amount, proof } = getters.sevPayloadFor(account)
            console.log(index, amount, proof)
            const tx = await sevDistributorContract.claimAndStake(
                index, 
                account, 
                amount, 
                proof
            );
            const txReceipt = await tx.wait(1);
            return txReceipt.status
        }
        catch (err) {
            console.log("merkle/actions/claimAndStakeSev::err", err)
        }
        return false
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}