<template>
    <form action="">
        <div class="modal-card" style="width: 300px">
            <header class="modal-card-head">
                <p class="modal-card-title">Bid on Slot {{ index + 1 }}</p>
            </header>
            <section class="modal-card-body">
                <b-field label="Delegate Address" :type="delegate ? isDelegateValid ? 'is-success' : 'is-danger' : ''">
                    <b-input
                        type="text"
                        size="is-medium"
                        v-model="delegate"
                        :disabled="bidding"
                        placeholder="Address"
                    ></b-input>
                </b-field>

                <b-field label="Your Bid" :type="isBidValid ? 'is-success' : 'is-danger'">
                    <b-input
                        :value="format(bid)"
                        :disabled="bidding"
                        @input="onBidInput"
                        placeholder="Amount"
                        type="text"
                        size="is-medium has-text-right"
                        expanded
                    ></b-input>
                </b-field>

                <b-field label="Before you Bid">
                    <b-checkbox 
                      v-model="checkBox"
                      :disabled="bidding"
                    >Yes, I understand how bidding works and that my entire bid may be completely burned.</b-checkbox>
                </b-field>
            </section>
            <footer class="modal-card-foot">
                <b-button
                    label="Cancel"
                    :disabled="bidding"
                    @click="close" />
                <b-button
                    label="Bid"
                    type="is-primary"
                    :loading="bidding"
                    :disabled="!isBidEnabled"
                    @click="bidOnSlot"
                 />
            </footer>
        </div>
    </form>
</template>


<script>
  import { utils, constants } from "ethers";

  export default {
    components: {},
    computed: {
      isBidEnabled: function () {
        return this.checkBox 
          && this.delegate && this.isDelegateValid 
          && this.bid && this.isBidValid
      },
      isDelegateValid: function () {
        return utils.isAddress(this.delegate) && this.delegate !== constants.AddressZero
      },
      isBidValid: function () {
        return this.bid.gte(this.minimumBid)
      }
    },
    data() {
        return {
          delegate: null,
          bid: this.minimumBid,
          bidding: false,
          checkBox: false,
        }
    },
    props: [
      'index',
      'minimumBid',
    ],
    methods: {
      close() {
        this.delegate = null
        this.bid = this.minimumBid
        this.$emit('close')
      },
      async bidOnSlot() {
        this.bidding = true;
        let payload = {
          slotIndex: this.index,
          bidAmount: this.bid,
          delegate: this.delegate
        }
        let bidResult = await this.$store.dispatch('bidForSlot', payload);
        if (bidResult) {
          await this.$store.dispatch('updateSlotData');
        }
        this.bidding = false;
      },
      onBidInput(value) {
        this.amountToBid = this.inputToBigNumber(value);
      },
      format(value) {
        try {
          const decimal = utils.formatEther(value); // BN to string
          const formatted = decimal.replace(/[.]0*$/, '') // Drop trailing zeroes
          return formatted;
        }
        catch {
          return null;
        }
      },
      inputToBigNumber(value) {
        try {
          const validInput = value.match(/^[^\D]*[.]{0,1}[^\D]{0,18}/)[0]; // Match a valid decimal
          return utils.parseEther(validInput); // user input to BN
        }
        catch (err) {
          return null;
        }
      },
    },
  };
</script>