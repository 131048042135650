<template>
    <article>

        <div class="hero hero-image-holder">
          <img class="hero-img back" src="/assets/headers/eden-hero-background.jpg" alt="">
          <img class="hero-img fountain" src="/assets/headers/Eden_close_up.png" alt="">
          <div class="hero-body">
              <div class="container is-max-desktop">
                  <h1 class="title is-size-1 has-text-success">
                      Fountain of Eden
                  </h1>
                  <!-- <h2 class="subtitle">
                      Fountain of Eden
                  </h2> -->
                  <b-button class="is-primary" tag="a" href="https://docs.edennetwork.io/" target="_blank">
                    Learn More
                  </b-button>
              </div>
          </div>
          <div class="hero-footer">
            <div class="sweep-left-blue"></div>
          </div>
        </div>

        <div class="container is-max-desktop mt-5">
          <div class="columns">
            <div class="column is-4">
              <article class="notification is-primary has-text-centered">
                <p class="title is-1 mb-0" v-if="pendingEpochProducerBlocksRatio">
                  {{ pendingEpochProducerBlocksRatio * 100 | percentage_2 }}
                </p>
                <p class="title is-1 mb-0" v-else>
                  -
                </p>
                <hr />
                <p class="content has-text-weight-bold is-uppercase">Participating Hashrate</p>
              </article>
            </div>
            <div class="column is-4" v-if="merkleProof && distributorData">
              <epoch
                :number="epoch && `Epoch #${parseInt(epoch) + 1}` || `Current Epoch`"
                status="In progress"
                :endTimestamp="pendingEpochEndTimestamp"
                :distribution="pendingTokens"
                :hashrate="pendingEpochProducerBlocksRatio"
              />
            </div>
            
            <div class="column is-4" v-if="merkleProof && pendingRewardsData">
              <epoch
                :number="epoch && `Epoch #${parseInt(epoch)}` || `Last Epoch`"
                status="Completed"
                :endTimestamp="lastEpochEndTimestamp"
                :distribution="lastEpochTokenDistribution"
                :hashrate="lastEpochProducerBlocksRatio"
              />
            </div>
          </div>
        </div>

        <section>
            <div class="container is-max-desktop mt-5">
                <h1 class="title has-text-weight-bold is-size-3">
                    Block Producers of Eden
                </h1>
                <div class="columns is-multiline" v-if="governanceData">
                  <div class="column is-4" v-for="producer in filteredProducers" :key="producer.id">
                    <producer
                      :id="producer.id"
                      :active="producer.active"
                      :rewardCollector="producer.rewardCollector"
                      :rewards="producer.rewards"
                      :totalClaimed="producer.totalClaimed"
                      :totalSlashed="producer.totalSlashed"
                      :availableToClaim="producer.availableToClaim"
                      :confirmedBlocks="producer.confirmedBlocks"
                      :pendingEpochBlocks="producer.pendingEpochBlocks"
                    />
                  </div>
                </div>
            </div>
        </section>
    </article>
</template>
<script>
  import {ethers} from 'ethers';
  import {mapGetters} from 'vuex';
  import Epoch from '../components/Epoch'
  import Producer from '../components/Producer';

  const POLL_RATE = 15 * 1000;
  export default {
    components: {
      Epoch, Producer
    },
    computed: {
      ...mapGetters([
        'distributorData', 
        'governanceData',
        'pendingRewardsData',
        'merkleProof'
      ]),
      filteredProducers: function () {
        return this.governanceData?.filter(function (row) {
          return row.active;
        })
      },
      pendingTokens: function () {
        let tokens = this.pendingRewardsData?.activeRewardScheduleEntry.rewardsPerEpoch;
        return tokens && ethers.utils.formatUnits(tokens);
      },
      pendingEpochEndTimestamp: function () {
        let timestamp = this.pendingRewardsData?.pendingEpoch.startBlock.timestamp;
        let duration = this.pendingRewardsData?.activeRewardScheduleEntry.epochDuration;
        return timestamp && duration && parseInt(timestamp) + parseInt(duration);
      },
      pendingEpochProducerBlocksRatio: function () {
        return this.pendingRewardsData?.pendingEpoch.producerBlocksRatio;
      },
      lastEpochTokenDistribution: function () {
        let tokens = this.pendingRewardsData?.lastEpoch.rewardsPerEpoch;
        return tokens && ethers.utils.formatUnits(tokens);
      },
      lastEpochEndTimestamp: function () {
        return this.pendingRewardsData?.lastEpoch.endBlock.timestamp;
      },
      lastEpochProducerBlocksRatio: function () {
        return this.pendingRewardsData?.lastEpoch.producerBlocksRatio;
      },
      epoch: function () {
        return this.merkleProof?.epoch
      },
    },
    mounted() {
      this.handlePolling();
      this.polling = setInterval(this.handlePolling, POLL_RATE);
    },
    beforeDestroy() {
      clearInterval(this.polling);
    },
    methods: {
      async handlePolling() {
        // fountain data
        await this.$store.dispatch('updateDistributorData');
        await this.$store.dispatch('updateGovernanceData');
        await this.$store.dispatch('updatePendingRewardsData');
        await this.$store.dispatch('updateMerkleProof');
      }
    }
  };
</script>