import { request, gql } from 'graphql-request'
import { GOVERNANCE_SUBGRAPH } from '../settings';

export async function fetchPendingRewardsData() {
    const query  = gql`
    {
        rewardSchedules {
            activeRewardScheduleEntry {
                epochDuration
                rewardsPerEpoch
            }
            pendingEpoch {
                startBlock {
                    timestamp
                }
                epochNumber
                finalized
                producerBlocksRatio
            }
            lastEpoch {
                endBlock {
                    timestamp
                }
                epochNumber
                finalized
                producerBlocksRatio
            }
        }
    }`

    let data = await request(GOVERNANCE_SUBGRAPH, query);
    try {
        return data.rewardSchedules[0];
    }
    catch (err) {
        console.log(err);
        throw("Could not fetch data");
    }
}

export async function fetchGovernanceData() {
    const query  = gql`
    {
        producers(
            orderBy: rewards,
            orderDirection: desc
        ) {
            id,
            active,
            rewardCollector,
            rewards,
            confirmedBlocks,
            pendingEpochBlocks
        }
    }`

    let data = await request(GOVERNANCE_SUBGRAPH, query);
    try {
        return data.producers;
    }
    catch (err) {
        console.log(err);
        throw("Could not fetch data");
    }
}

export async function fetchProducerData(address) {
    const query  = gql`
    {
        producer(
            id: ${address.toLowerCase()}
        ) {
            id,
            active,
            rewardCollector,
            rewards,
            confirmedBlocks,
            pendingEpochBlocks
        }
        producers(
            where: { rewardCollector: ${address.toLowerCase()} }
        ) {
            id,
            active,
            rewardCollector,
            rewards,
            confirmedBlocks,
            pendingEpochBlocks
        }
    }`

    let data = await request(GOVERNANCE_SUBGRAPH, query);
    try {
        return data;
    }
    catch (err) {
        console.log(err);
        throw("Could not fetch data");
    }
}