<template>
  <div class="box content">
    <p class="title is-size-4">
      Slot {{ index + 1 }}
    </p>
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <b-icon icon="home"></b-icon>
        </div>
        <div class="level-item">
          <p class="heading">Tenant</p>
        </div>
      </div>
      <div class="level-right has-text-right">
        <div class="level-item">
          <p>
            <a :href="bidder | explorerLinkToAddress" target="_blank">{{ bidder | shortEth }}</a>
          </p>
        </div>
      </div>
    </div>
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <b-icon icon="bullseye-arrow"></b-icon>
        </div>
        <div class="level-item">
          <p class="heading">Delegate</p>
        </div>
      </div>
      <div class="level-right has-text-right">
        <div class="level-item content">
          <p>
            <a :href="delegate | explorerLinkToAddress" target="_blank">{{ delegate | shortEth }}</a>
          </p>
        </div>
      </div>
    </div>
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <b-icon icon="gavel"></b-icon>
        </div>
        <div class="level-item">
          <p class="heading">Current Bid</p>
        </div>
      </div>
      <div class="level-right has-text-right">
        <div class="level-item">
          <p v-if="expiry.gt('0')">
            <strong>{{ bidAmount | fromWei | currency }} EDEN</strong><br />
            {{ periodStart | relativeTime }}
          </p>
          <p v-else>
            n/a
          </p>
        </div>
      </div>
    </div>
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <b-icon icon="fire"></b-icon>
        </div>
        <div class="level-item">
          <p class="heading">Fees Burned</p>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <p v-if="expiry.gt('0')">
            <strong>{{ burned() | fromWei | currency_2 }} EDEN</strong><br />
          </p>
          <p v-else>
            n/a
          </p>
        </div>
      </div>
    </div>
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <b-icon icon="currency-usd"></b-icon>
        </div>
        <div class="level-item">
          <p class="heading">Minimum Bid</p>
        </div>
      </div>
      <div class="level-right has-text-right">
        <div class="level-item">
          <p v-if="expiry.gt('0')">
            <strong>{{ minimumBid | fromWei | currency }} EDEN</strong>
          </p>
          <p v-else>
            n/a
          </p>
        </div>
      </div>
    </div>
    <div class="has-text-centered">
      <b-button
        v-if="account && account.toLowerCase() == bidder.toLowerCase()"
        expanded
        size="is-large"
        @click="isUpdateDelegateModalActive = true"
      >
          Change Delegate
      </b-button>
      <b-button
        v-else
        expanded
        type="is-primary"
        size="is-large"
        @click="isComponentModalActive = true"
        :disabled="!account || account.toLowerCase() == bidder.toLowerCase()"
      >
          Bid on Slot
      </b-button>
      <!-- <b-button
        expanded
        size="is-medium"
        tag="a"
        href="https://docs.edennetwork.io/slot-tenants/faq"
        target="_blank"
      >
          Bid on Slot
      </b-button> -->
      <!-- <p class="small" v-if="expiry.gt('0')">
        Resets {{ expiry | relativeTime }}
      </p> -->
    </div>

    <b-modal
        v-model="isComponentModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Modal"
        aria-modal>
        <template #default="props">
            <bid-modal 
              v-bind="formProps" 
              :index="index" 
              :minimumBid="minimumBid"
              @close="props.close"
            ></bid-modal>
        </template>
    </b-modal>

    <b-modal
      v-model="isUpdateDelegateModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Modal"
      aria-modal
    >
      <template #default="props">
        <update-delegate-modal
          v-bind="formProps" 
          :index="index" 
          :oldDelegate="delegate" 
          @close="props.close"
        ></update-delegate-modal>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import BidModal from '../components/BidModal';
  import UpdateDelegateModal from '../components/UpdateDelegateModal';
  import {mapGetters} from 'vuex';
  import { metaDataForApplication } from '../services/NameMaps';
  
  export default {
    components: {
      BidModal,
      UpdateDelegateModal
    },
    computed: {
      ...mapGetters({
        account: 'accountModule/account',
      }),
      name: function () {
        return metaDataForApplication(this.delegate?.toLowerCase());
      },
      src: function () {
        return metaDataForApplication(this.delegate).src;
      }
    },
    data() {
      return {
        isComponentModalActive: false,
        isUpdateDelegateModalActive: false,
        formProps: {
          delegate: null,
          bid: null
        },
        bidding: false,
      };
    },
    props: [
      'index',
      'balance',
      'bidAmount',
      'bidder',
      'delegate',
      'expiry',
      'minimumBid',
      'periodStart'
    ],
    methods: {
      burned() {
        return this.bidAmount.sub(this.balance);
      },
    },
  };
</script>
