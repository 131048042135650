import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import {ethers} from 'ethers';
import IPFS from 'ipfs';

import FormulaABI from '../contracts/ArchFormula';
import PairABI from '../contracts/UniswapV2Pair';
import RegistryABI from '../contracts/TokenRegistry';
import RewardsManagerABI from '../contracts/RewardsManager';
import TokenABI from '../abis/EdenToken';
import VotingPowerABI from '../contracts/VotingPower';
import DistributorABI from '../abis/Distributor';
import GovernanceABI from '../abis/Governance';
import SlotMarketABI from '../abis/SlotMarket';

import DeployedAddresses from '../contracts/Deployed';

import accountModule from './account';
import merkle from './merkle';

import { initOnboard, initNotify } from '../services/BlocknativeServices';
import { fetchData, fetchProducerData } from '../services/DistributionSubgraph';
import { fetchGovernanceData, fetchPendingRewardsData } from '../services/GovernanceSubgraph';
import { RPC_URL } from '../settings';
import { getPricesFromCoinGecko } from '../services/CoinGeckoPrices';

let ipfs;
let provider = new ethers.providers.JsonRpcProvider(RPC_URL);
let signer;
// const onboard = initOnboard({
//   wallet: (wallet) => {
//     provider = new ethers.providers.Web3Provider(wallet.provider);
//   },
// });

// const notify = initNotify();

export default new Vuex.Store({
  modules: {
    accountModule,
    merkle
  },
  state: {
    approvedBalances: null,
    contracts: null,
    depositWithPermit: null,
    distributionCount: null,
    distributorData: null,
    governanceData: null,
    pendingRewardsData: null,
    merkleProof: null,
    onboard: null,
    pendingBalances: null,
    slotData: null,
    stakedBalances: null,
    stakeWithPermit: null,
    stakingTokenList: null,
    tokenBalances: null,
    votingPower: null
  },
  mutations: {
    setApprovedBalances(state, approvedBalances) {
      state.approvedBalances = approvedBalances;
    },
    setContracts(state, contracts) {
      state.contracts = contracts;
    },
    setDepositWithPermit(state, depositWithPermit) {
      state.depositWithPermit = depositWithPermit;
    },
    setDistributorData(state, distributorData) {
      state.distributorData = distributorData;
    },
    setDistributionCount(state, distributionCount) {
      state.distributionCount = distributionCount;
    },
    setGovernanceData(state, governanceData) {
      state.governanceData = governanceData;
    },
    setPendingRewardsData(state, pendingRewardsData) {
      state.pendingRewardsData = pendingRewardsData
    },
    setMerkleProof(state, merkleProof) {
      state.merkleProof = merkleProof;
    },
    setOnboard(state, onboard) {
      state.onboard = onboard;
    },
    setPendingBalances(state, pendingBalances) {
      state.pendingBalances = pendingBalances;
    },
    setSlotData(state, slotData) {
      state.slotData = slotData;
    },
    setStakedBalances(state, stakedBalances) {
      state.stakedBalances = stakedBalances;
    },
    setStakeWithPermit(state, stakeWithPermit) {
      state.stakeWithPermit = stakeWithPermit;
    },
    setStakingTokenList(state, stakingTokenList) {
      state.stakingTokenList = stakingTokenList;
    },
    setTokenBalances(state, tokenBalances) {
      state.tokenBalances = tokenBalances;
    },
    setVotingPower(state, votingPower) {
      state.votingPower = votingPower;
    },
  },
  actions: {
    async handleFirstUpdate({commit, dispatch}) {
      try {
        ipfs = await IPFS.create();
        let chain = await provider.getNetwork();
        commit('accountModule/setChainId', chain.chainId.toString());
        await dispatch('setupContracts');
        await dispatch('merkle/loadSev');
      } catch {}
    },
    async bootstrap({state, dispatch, commit, rootGetters}, {onSuccessCallback}) {
      let onboard = initOnboard({
        address: (address) => {
          let account = rootGetters['accountModule/account']
          if (account == address) return;
          dispatch('clearAccountState');
          commit('accountModule/setAccount', address);
          dispatch('getTokenBalancesForUser');
          dispatch('getStakedBalancesForUser');
          dispatch('merkle/loadAccountState');
        },
        network: (network) => {
          let chainId = rootGetters['accountModule/chainId']
          if (chainId == network.toString()) return;
          dispatch('clearNetworkState');
          commit('accountModule/setChainId', network.toString());
          dispatch('getTokenBalancesForUser');
          dispatch('setupContracts');
        },
        wallet: (wallet) => {
          provider = new ethers.providers.Web3Provider(wallet.provider);
          window.localStorage.setItem('selectedWallet', wallet.name);
        }
      });
      commit('setOnboard', onboard);

      const previouslySelectedWallet = window.localStorage.getItem('selectedWallet');
      if (previouslySelectedWallet) {
        await onboard.walletSelect(previouslySelectedWallet);
      }
      else {
        await onboard.walletSelect();
      }
      
      try {
        const readyToTransact = await onboard.walletCheck();
        if (readyToTransact) {
          signer = provider.getSigner();
          // const onboardState = onboard.getState();
          await dispatch('setupContracts');
  
          if (onSuccessCallback) {
            onSuccessCallback();
          }
        }

      }
      catch {}
    },
    async clearAccountState({commit}) {
      commit('accountModule/setAccount', null);
      commit('setApprovedBalances', null);
      commit('setDepositWithPermit', null);
      commit('setStakedBalances', null);
      commit('setStakeWithPermit', null);
      commit('setStakingTokenList', null);
      commit('setTokenBalances', null);
    },
    async clearNetworkState({commit}) {
      commit('setContracts', null);
      commit('setApprovedBalances', null);
      commit('setDepositWithPermit', null);
      commit('setStakedBalances', null);
      commit('setStakeWithPermit', null);
      commit('setStakingTokenList', null);
      commit('setTokenBalances', null);
    },
    async disconnect({dispatch, commit}) {
      window.localStorage.removeItem('selectedWallet');
      dispatch('clearAccountState');
      commit('setOnboard', null);
    },
    async updateDistributorData({state, commit, rootGetters}) {
      let chainId = rootGetters['accountModule/chainId']
      if (!chainId) return;
      
      const distributorContractAddress = DeployedAddresses.MerkleDistributor[chainId];
      let data = await fetchData(distributorContractAddress);
      commit('setDistributorData', data);
    },
    async updatePendingRewardsData({commit}) {
      let data = await fetchPendingRewardsData();
      commit('setPendingRewardsData', data);
    },
    async updateSlotData({state, commit}) {
      if (state.contracts) {
        const { slotMarketContract } = state.contracts;
        let data = []
        const slots = 3
        for (let i = 0; i < slots; i++) {
          let row = {}
          let [
            slotBid,
            delegate,
            minimumBid,
            expiry,
            balance
          ] = await Promise.all([
            slotMarketContract.slotBid(i),
            slotMarketContract.slotDelegate(i),
            slotMarketContract.slotCost(i),
            slotMarketContract.slotExpiration(i),
            slotMarketContract.slotBalance(i)
          ]);
          row['bidder'] = slotBid.bidder;
          row['delegate'] = delegate;
          row['periodStart'] = slotBid.periodStart;
          row['bidAmount'] = slotBid.bidAmount;
          row['minimumBid'] = minimumBid;
          row['expiry'] = expiry;
          row['balance'] = balance;
          data.push(row);
        }
        commit('setSlotData', data);
      }
    },
    async updateGovernanceData({state, commit}) {
      let data = await fetchGovernanceData();
      if (state.contracts) {
        const { distributorContract } = state.contracts;
        let augmentedData = []
        for (const row of data) {
          try {
            const accountState = await distributorContract.accountState(row.id);
            let availableToClaim = ethers.BigNumber.from("0");
            if (
              ethers.BigNumber.from(row.rewards).gt(
                accountState.totalClaimed.add(accountState.totalSlashed)
              )
            ) availableToClaim = ethers.BigNumber.from(row.rewards).sub(accountState.totalClaimed).sub(accountState.totalSlashed);
            augmentedData.push({
              ...row,
              ...accountState,
              availableToClaim
            })
          } catch {}
        }
        commit('setGovernanceData', augmentedData);
        return;
      }
      commit('setGovernanceData', data);
    },
    async setupContracts({state, commit, dispatch, rootGetters}) {
      let chainId = rootGetters['accountModule/chainId']
      if (!chainId) return;

      const distributorContractAddress = DeployedAddresses.MerkleDistributor[chainId];
      const distributorContract = new ethers.Contract(
        distributorContractAddress,
        DistributorABI,
        signer || provider
      );

      const sevDistributorContractAddress = DeployedAddresses.SevDistributor[chainId];
      const sevDistributorContract = new ethers.Contract(
        sevDistributorContractAddress,
        DistributorABI,
        signer || provider
      );

      const governanceContractAddress = DeployedAddresses.DistributorGovernance[chainId];
      const governanceContract = new ethers.Contract(
        governanceContractAddress,
        GovernanceABI,
        signer
      );

      const slotMarketContractAddress = DeployedAddresses.EdenNetworkProxy[chainId];
      const slotMarketContract = new ethers.Contract(
        slotMarketContractAddress,
        SlotMarketABI,
        signer || provider
      );

      const rewardsManagerContractAddress = DeployedAddresses.RewardsManager[chainId];
      const rewardsManagerContract = new ethers.Contract(
        rewardsManagerContractAddress,
        RewardsManagerABI,
        signer
      );

      const sushiSwapPairAddress = DeployedAddresses.SLP[chainId];
      const sushiSwapPairContract = new ethers.Contract(
        sushiSwapPairAddress,
        PairABI,
        signer
      );

      const tokenContractAddress = DeployedAddresses.EDEN[chainId];
      const tokenContract = new ethers.Contract(
        tokenContractAddress,
        TokenABI,
        signer
      );

      commit('setContracts', {
        distributorContract,
        sevDistributorContract,
        governanceContract,
        slotMarketContract,
        rewardsManagerContract,
        sushiSwapPairContract,
        tokenContract, 
      });
      dispatch('getTokenBalancesForUser');
      dispatch('getStakedBalancesForUser');
      dispatch('getStakingTokenList');
      dispatch('getApprovedBalancesForUser');
      // fountain data
      dispatch('updateDistributorData');
      dispatch('updateGovernanceData');
      dispatch('updatePendingRewardsData');
      dispatch('updateMerkleProof');
      dispatch('updateSlotData');
    },
    async getFarmInfo({state}) {
      if (state.contracts) {
        const { sushiSwapPairContract, rewardsManagerContract } = state.contracts;
        const [
          slpSupply,
          slpStaked,
          reserves,
          rewardsPerBlock,
          prices
        ] = await Promise.all([
          sushiSwapPairContract.totalSupply(),
          sushiSwapPairContract.balanceOf(rewardsManagerContract.address),
          sushiSwapPairContract.getReserves(),
          rewardsManagerContract.rewardTokensPerBlock(),
          getPricesFromCoinGecko()
        ]);

        const edenReserve = reserves._reserve0;
        const ethReserve = reserves._reserve1;

        const farmEth = ethReserve.mul("2").mul(slpStaked).div(slpSupply);
        const ethPriceUsd = prices[0].data.market_data.current_price.usd;
        const farmUsd = parseInt(ethers.utils.formatUnits(farmEth)) * ethPriceUsd;

        const farmEden = edenReserve.mul("2").mul(slpStaked).div(slpSupply);

        const rewardsPerDay = rewardsPerBlock.mul("6300");
        const roiPerDay = parseInt(rewardsPerDay.mul("1000000").div(farmEden).toString()) / 1000000

        return {
          slpStaked,
          rewardsPerBlock,
          farmUsd,
          roiPerDay
        }
      }
    },
    async updateMerkleProof({state, commit}) {
      if (state.contracts) {
        const { distributorContract } = state.contracts;
        let distributionCount
        try {
          distributionCount = await distributorContract.distributionCount();
        }
        catch (err) {
          console.log("updateMerkleProof::err", err);
          return;
        }

        if (state.distributionCount == distributionCount) return;

        let tokenURI;
        try {
          tokenURI = await distributorContract.tokenURI(distributionCount);
        }
        catch (err) {
          console.log("updateMerkleProof::err", err);
          return;
        }

        let merkleProof;
        for await (const data of ipfs.cat(tokenURI.replace("ipfs://",""))) {
          merkleProof = JSON.parse(String.fromCharCode.apply(String, data))
        }
        // update distribution count and merkle proof together
        commit('setDistributionCount', distributionCount);
        commit('setMerkleProof', merkleProof);
      }
    },
    async getApprovedBalancesForUser({state, commit, rootGetters}) {
      let account = rootGetters['accountModule/account']
      if (state.contracts && account) {
        const { tokenContract, slotMarketContract, rewardsManagerContract, sushiSwapPairContract } = state.contracts;
        let approvedBalances = {};
        approvedBalances[tokenContract.address] = {}
        approvedBalances[tokenContract.address][slotMarketContract.address] = await tokenContract.allowance(account, slotMarketContract.address);
        approvedBalances[sushiSwapPairContract.address] = {}
        approvedBalances[sushiSwapPairContract.address][rewardsManagerContract.address] = await sushiSwapPairContract.allowance(account, rewardsManagerContract.address);
        commit('setApprovedBalances', approvedBalances);
      }
    },
    async getStakedBalancesForUser({state, commit, rootGetters}) {
      let account = rootGetters['accountModule/account']
      if (state.contracts && account) {
        const { slotMarketContract, rewardsManagerContract } = state.contracts;
        let stakedBalances = {};
        try {
          stakedBalances['slotMarket'] = await slotMarketContract.stakedBalance(account);
        } catch {}
        try {
          const pid = 0;
          const userInfo = await rewardsManagerContract.userInfo(pid, account);
          const pending = await rewardsManagerContract.pendingRewardTokens(pid, account);
          stakedBalances['farmDeposits'] = userInfo.amount;
          stakedBalances['farmRewards'] = pending;
        } catch {}
        commit('setStakedBalances', stakedBalances);
      }
    },
    async getStakingTokenList({state, commit}) {
      if (state.contracts) {
        const { tokenContract } = state.contracts;
        const stakingTokenList = [];

        stakingTokenList.push({ 
          symbol: await tokenContract.symbol(), 
          address: tokenContract.address
        });
        commit('setStakingTokenList', stakingTokenList);
      }
    },
    async getTokenBalancesForUser({state, commit, rootGetters}) {
      let account = rootGetters['accountModule/account']
      if (state.contracts && account) {
        const { tokenContract, sushiSwapPairContract } = state.contracts;
        let tokenBalances = {};
        tokenBalances[tokenContract.address] = await tokenContract.balanceOf(account);
        tokenBalances[sushiSwapPairContract.address] = await sushiSwapPairContract.balanceOf(account);
        commit('setTokenBalances', tokenBalances);
      }
    },
    async bidForSlot({state, rootGetters}, payload) {
      let account = rootGetters['accountModule/account']
      if (state.contracts && account) {
        const { slotMarketContract } = state.contracts;
        const { slotIndex, bidAmount, delegate } = payload;
        try {
          const tx = await slotMarketContract.claimSlot(
            slotIndex, bidAmount, delegate
          );
          const txReceipt = await tx.wait(1);
          return txReceipt.status;
        }
        catch (err) {
          console.log(err)
        }
      }
      return false;
    },
    async updateSlotDelegate({state, rootGetters}, payload) {
      let account = rootGetters['accountModule/account']
      if (state.contracts && account) {
        const { slotMarketContract } = state.contracts;
        const { slotIndex, delegate } = payload;
        try {
          const tx = await slotMarketContract.setSlotDelegate(slotIndex, delegate);
          const txReceipt = await tx.wait(1);
          return txReceipt.status;
        }
        catch (err) {
          console.log(err);
        }
      }
      return false;
    },
    async claimFromDistributor({state, rootGetters}, payload) {
      let account = rootGetters['accountModule/account']
      if (state.contracts && account) {
        const { distributorContract } = state.contracts;
        const { index, account, totalEarned, proof } = payload
        try {
          const tx = await distributorContract.claim(
            index,
            account,
            totalEarned,
            proof
          );
          const txReceipt = await tx.wait(1);
          return txReceipt.status;
        }
        catch (err) {
          console.log(err)
        }
      }
      return false;
    },
    async claim({state, rootGetters}) {
      let account = rootGetters['accountModule/account']
      if (state.contracts && account) {
        const {vestingContract} = state.contracts;
        try {
          const tx = await vestingContract.claimVestedTokens(account);
          const txReceipt = await tx.wait(1);
          return txReceipt.status;
        }
        catch (err) {
          return false;
        }
      }
    },
    async approve({state, rootGetters}, payload) {
      // Approval function for EIP-712
      // Note: this solution uses provider.sent("eth_signTypedData_v4", ...)
      // instead of experimental _signTypedData. This is not supported by Ledger
      // and Trezor, so there is a fallback for normal approvals.
      // See https://github.com/ethers-io/ethers.js/issues/298
      let account = rootGetters['accountModule/account']
      if (state.contracts && account) {
        const { token, amount, spender } = payload
        const { tokenContract } = state.contracts;

        try {
          const chain = await provider.getNetwork();
          const name = await tokenContract.name(); // token name
          const version = "1";
          const chainId = chain.chainId.toString();
          const verifyingContract = ethers.utils.getAddress(spender);

          const nonce = await tokenContract.nonces(account);
          const deadline = parseInt(Date.now() / 1000) + 1200; // now plus 20 mins

          const domain = {
            name,
            version,
            chainId,
            verifyingContract
          };

          const types = {
            EIP712Domain: [
              { name: "name", type: "string" },
              { name: "version", type: "string" },
              { name: "chainId", type: "uint256" },
              { name: "verifyingContract", type: "address" },
            ],
            Permit: [
              { name: "owner", type: "address" },
              { name: "spender", type: "address" },
              { name: "value", type: "uint256" },
              { name: "nonce", type: "uint256" },
              { name: "deadline", type: "uint256" },
            ]
          };

          const value = {
            owner: ethers.utils.getAddress(account),
            spender: ethers.utils.getAddress(spender),
            value: amount.toString(),
            nonce: nonce.toString(),
            deadline: deadline.toString(),
          };

          const msgParams = JSON.stringify({
            types,
            domain,
            primaryType: "Permit",
            message: value,
          });

          const params = [account, msgParams];

          const signature = await provider.send("eth_signTypedData_v4", params);
          this.commit('setStakeWithPermit', {
            amount: amount.toString(),
            deadline: deadline.toString(),
            r: ethers.utils.arrayify("0x" + signature.substring(2).substring(0,64)),
            s: ethers.utils.arrayify("0x" + signature.substring(2).substring(64, 128)),
            v: parseInt(signature.substring(2).substring(128, 130), 16),
          });
          return true;
        }
        catch (err) {
          console.log(err);
          // if (err.code == -32603) {
            // MetaMask Message Signature: Error: Not supported on this device
            // Use on-chain approval method
            try {
              const tx = await tokenContract.approve(spender, ethers.constants.MaxUint256);
              const txReceipt = await tx.wait(1);
              return txReceipt.status;
            }
            catch (err) {
              return false;
            }
          // }
          // return false;
        }
      }
    },
    async approveSimple({state, rootGetters}, payload) {
      let account = rootGetters['accountModule/account']
      if (state.contracts && account) {
        const { token, amount, spender } = payload;
        
        try {
          let _tokenContract = new ethers.Contract(token, TokenABI, signer);
          const tx = await _tokenContract.approve(spender, ethers.constants.MaxUint256);
          const txReceipt = await tx.wait(1);
          return txReceipt.status;
        }
        catch {}
      }
      return false;
    },
    async depositWithPermit({state, rootGetters}, amountToStake) {
      let account = rootGetters['accountModule/account']
      if (state.contracts && account) {
        const {rewardsManagerContract} = state.contracts;

        if (state.depositWithPermit) {
          // Handle depositWithPermit
          const {pid, amount, deadline, v, r, s} = state.depositWithPermit;
  
          try {
            const tx = await rewardsManagerContract.depositWithPermit(pid, amount, deadline, v, r, s);
            const txReceipt = await tx.wait(1);
            return txReceipt.status;
          }
          catch (err) {
            return false;
          }
        }
        else {
          // Handle deposit
          try {
            let pid = 0; // Hardcoded RewardsManager PID
            const tx = await rewardsManagerContract.deposit(pid, amountToStake);
            const txReceipt = await tx.wait(1);
            return txReceipt.status;
          }
          catch (err) {
            return false;
          }
        }
      }
    },
    async stakeWithPermit({state, rootGetters}, amountToStake) {
      let account = rootGetters['accountModule/account']
      if (state.contracts && account) {
        const { slotMarketContract } = state.contracts;

        if (state.stakeWithPermit) {
          // Handle stake with permit
          const {amount, deadline, v, r, s} = state.stakeWithPermit;
  
          try {
            const tx = await slotMarketContract.stakeWithPermit(amount, deadline, v, r, s);
            const txReceipt = await tx.wait(1);
            return txReceipt.status;
          }
          catch (err) {
            console.log(err);
            return false;
          }
        }
        else {
          // Handle stake
          try {
            const tx = await slotMarketContract.stake(amountToStake);
            const txReceipt = await tx.wait(1);
            // updated approved amount?
            return txReceipt.status;
          }
          catch (err) {
            console.log(err);
            return false;
          }
        }
      }
    },
    async depositSimple({state, rootGetters}, amountToDeposit) {
      let account = rootGetters['accountModule/account']
      if (state.contracts && account) {
        const { rewardsManagerContract } = state.contracts;
        try {
          const pid = 0;
          const tx = await rewardsManagerContract.deposit(pid, amountToDeposit);
          const txReceipt = await tx.wait(1);
          return txReceipt.status;
        }
        catch (err) {
          return false;
        }
      }
    },
    async withdrawSimple({state, rootGetters}, amountToWithdraw) {
      let account = rootGetters['accountModule/account']
      if (state.contracts && account) {
        const { rewardsManagerContract } = state.contracts;
        try {
          const pid = 0;
          const tx = await rewardsManagerContract.withdraw(pid, amountToWithdraw);
          const txReceipt = await tx.wait(1);
          return txReceipt.status;
        }
        catch (err) {
          return false;
        }
      }
    },
    async unstake({state, rootGetters}, unstakeAmount) {
      let account = rootGetters['accountModule/account']
      if (state.contracts && account) {
        const { slotMarketContract } = state.contracts;
        try {
          const tx = await slotMarketContract.unstake(unstakeAmount);
          const txReceipt = await tx.wait(1);
          return txReceipt.status;
        }
        catch (err) {
          return false;
        }
      }
    },
  },
  getters: {
    contracts: (state) => state.contracts,
    approvedBalances: (state) => state.approvedBalances,
    depositWithPermit: (state) => state.depositWithPermit,
    distributorData: (state) => state.distributorData,
    governanceData: (state) => state.governanceData,
    pendingRewardsData: (state) => state.pendingRewardsData,
    slotData: (state) => state.slotData,
    onboard: (state) => state.onboard,
    merkleProof: (state) => state.merkleProof,
    votingPower: (state) => state.votingPower,
    pendingBalances: (state) => state.pendingBalances,
    stakeWithPermit: (state) => state.stakeWithPermit,
    stakedBalances: (state) => state.stakedBalances,
    stakingTokenList: (state) => state.stakingTokenList,
    tokenBalances: (state) => state.tokenBalances
  },
});
