<template>
    <form action="">
        <div class="modal-card" style="width: 300px">
            <header class="modal-card-head">
                <p class="modal-card-title">Update Slot {{ index + 1 }}</p>
            </header>
            <section class="modal-card-body">
                <b-field label="Current Delegate Address">
                    <b-input
                        type="text"
                        size="is-medium"
                        :value="oldDelegate"
                        placeholder="Address"
                        disabled
                    ></b-input>
                </b-field>

                <b-field label="New Delegate Address" :type="delegate ? isDelegateValid ? 'is-success' : 'is-danger' : ''">
                    <b-input
                        type="text"
                        size="is-medium"
                        v-model="delegate"
                        placeholder="Address"
                    ></b-input>
                </b-field>
            </section>
            <footer class="modal-card-foot">
                <b-button
                    label="Cancel"
                    :disabled="updating"
                    @click="close" />
                <b-button
                    label="Update"
                    type="is-primary"
                    :disabled="!isUpdateEnabled"
                    :loading="updating"
                    @click="setSlotDelegate"
                />
            </footer>
        </div>
    </form>
</template>


<script>
  import { utils, constants } from "ethers";

  export default {
    components: {},
    computed: {
      isDelegateValid: function () {
        return utils.isAddress(this.delegate) 
            && this.delegate !== constants.AddressZero
            && utils.getAddress(this.delegate) !== utils.getAddress(this.oldDelegate)
      },
      isUpdateEnabled: function () {
          return this.delegate && this.isDelegateValid
      }
    },
    data() {
        return {
            updating: false,
            delegate: null,
        }
    },
    props: [
      'index',
      'oldDelegate',
      'bid',
    ],
    methods: {
      close() {
        this.delegate = null
        this.$emit('close')
      },
      async setSlotDelegate() {
        this.updating = true;
        const payload = {
            slotIndex: this.index,
            delegate: utils.getAddress(this.delegate)
        }
        let updateResult = await this.$store.dispatch('updateSlotDelegate', payload);
        if (updateResult) {
          await this.$store.dispatch('updateSlotData');
        }
        this.delegate = null;
        this.updating = false;
      },
    },
  };
</script>