<template>
    <div class="box">
      <div class="columns is-mobile">
        <div class="column">
          <p class="title is-4">{{ number }}</p>
          <p class="subtitle is-6">{{ ended ? `Ended ${lastEpochEndBlock}` : `Ends ${lastEpochEndBlock}` }}</p>
        </div>
        <div class="column is-narrow">
          <p class="content">{{ ended ? `Completed` : `In Progress` }}</p>
        </div>
      </div>
      <!-- <p class="heading">
        Hashrate this Epoch
      </p>
      <p class="content">
        <strong>{{ hashrate * 100 | percentage_2 }}</strong>
      </p> -->
      <p class="heading" v-if="distribution">
        {{ ended ? `Distributed this Epoch` : `EDEN to Distribute` }}
      </p>
      <p class="content" v-if="distribution">
        <strong>{{ distribution | currency }} EDEN</strong>
      </p>
      <!-- <p class="content" v-if="ended">
        <b-message type="is-success" size="is-small">
          Rewards through this epoch are available to claim.
        </b-message>
      </p>
      <p class="content" v-else>
        <b-message size="is-small">
          Rewards from this epoch will be available to claim shortly after the epoch ends.
        </b-message>
      </p> -->
    </div>
</template>

<script>
  import moment from 'moment';
  
  export default {
    components: {},
    computed: {
      ended: function () {
        return parseInt(this.endTimestamp) < moment().utc().unix();
      },
      lastEpochEndBlock: function () {
        return this.endTimestamp && moment.unix(parseInt(this.endTimestamp)).fromNow();
      },
    },
    props: [
      'number', 
      'status',
      'endTimestamp',
      'distribution',
      'hashrate'
    ],
    methods: {},
  };
</script>
