<template>
    <section>
        <div class="columns">
          <div class="column is-two-fifths">
            <article class="notification is-primary has-text-centered">
              <p class="title is-1 mb-0" v-if="stakedBalance && stakedBalance.eq('0')">
                -
              </p>
              <p class="title is-1 mb-0" v-else-if="stakedBalance">
                <animated-number
                  :value="stakedBalance.toString()"
                  :duration="600"
                  :formatValue="to0DpAndCurrencyFormatted"
                />
              </p>
              <hr />
              <p class="has-text-weight-bold is-uppercase">My Staked EDEN</p>
            </article>
            <article class="notification is-primary has-text-centered">
              <p class="title is-1 mb-0" v-if="earned && earned.eq('0')">
                -
              </p>
              <p class="title is-1 mb-0" v-else-if="earned">
                <animated-number
                  :value="earned.toString()"
                  :duration="600"
                  :formatValue="to0DpAndCurrencyFormatted"
                />
              </p>
              <hr />
              <p class="has-text-weight-bold is-uppercase">My Earned EDEN</p>
            </article>
            <article class="box content">
              <p class="title is-4 mb-0">
                How do I earn more EDEN?
              </p>
              <p>
                Rewards are variable. Your rewards are based on your activity relative to other stakers.
              </p>
              <ul>
                <li>Submit transactions with Eden RPC</li>
                <li>Stake EDEN tokens</li>
              </ul>
            </article>
          </div>
          <div class="column">
            <b-tabs v-model="activeTab" expanded class="p-0" size="is-medium" type="is-boxed">
              <b-tab-item label="Stake">
                <p class="content">Increase your transaction priority by staking at least 100 EDEN</p>
                <b-field grouped label="Stake EDEN tokens" class="mt-6">
                  <b-input 
                    :value="format(amountToStake)"
                    :autofocus="true"
                    :disabled="approving || staking"
                    @input="onStakeInput"
                    placeholder="Amount"
                    type="text" 
                    size="is-medium has-text-right"
                    expanded
                  >
                  </b-input>
                  <p class="control">
                      <b-button 
                        :disabled="approving || staking"
                        type="is-text" 
                        size="is-medium"
                        @click="setMax(true)"
                      >
                        Max
                      </b-button>
                  </p>
                </b-field>
                <b-field label="My new wallet balance" class="mt-4">
                  <p>
                    {{ getNewBalance(tokenBalance, amountToStake, false) }} EDEN
                    <!-- <a :href="sushiUrl" target="_blank">
                      <span>Get more</span>
                      <span class="icon is-small ml-1">
                        <i class="mdi mdi-arrow-top-right"></i>
                      </span>
                    </a> -->
                  </p>
                </b-field>
                <b-field label="My new staked balance" class="mt-4">
                  <p>
                    {{ getNewBalance(stakedBalance, amountToStake) }} EDEN
                    <br />
                    <a href="https://explorer.edennetwork.io/" target="_blank">
                      <span>Where will I rank?</span>
                      <span class="icon is-small ml-1">
                        <i class="mdi mdi-arrow-top-right"></i>
                      </span>
                    </a>
                  </p>
                </b-field>
                <div class="columns mt-4">
                  <div class="column">
                    <b-button 
                            rounded
                            type="is-success"
                            size="is-large"
                            :disabled="approved || !isAmountToStakeValid()"
                            @click="approve"
                            :loading="approving"
                            expanded
                    >
                        Approve
                    </b-button>
                  </div>
                  <div class="column">
                    <b-button 
                            rounded
                            type="is-success"
                            size="is-large"
                            :disabled="!approved || !isAmountToStakeValid()"
                            @click="stake"
                            :loading="staking"
                            expanded
                    >
                        Stake
                    </b-button>
                  </div>
                </div>
              </b-tab-item>
              <b-tab-item label="Unstake">
                <p class="content">Decrease your transaction priority</p>
                <b-field grouped label="Unstake EDEN tokens"  class="mt-6">
                  <b-input 
                    :disabled="withdrawing"
                    :value="format(amountToUnstake)"
                    @input="onUnstakeInput"
                    placeholder="Amount"
                    type="text" 
                    size="is-medium has-text-right"
                    expanded
                  ></b-input>
                  <p class="control">
                      <b-button 
                        :disabled="withdrawing"
                        type="is-text" 
                        size="is-medium"
                        @click="setMax(false)"
                      >
                        Max
                      </b-button>
                  </p>
                </b-field>
                <b-field label="My new wallet balance" class="mt-4">
                  <p>
                    {{ getNewBalance(tokenBalance, amountToUnstake) }} EDEN
                  </p>
                </b-field>
                <b-field label="My new staked balance" class="mt-4">
                  <p class="is-family-secondary">
                    {{ getNewBalance(stakedBalance, amountToUnstake, false) }} EDEN
                    <br />
                    <a href="https://explorer.edennetwork.io/" target="_blank">
                      <span>Where will I rank?</span>
                      <span class="icon is-small ml-1">
                        <i class="mdi mdi-arrow-top-right"></i>
                      </span>
                    </a>
                  </p>
                </b-field>
                <div class="columns mt-4">
                  <div class="column">
                    <b-button
                      expanded
                      rounded
                      type="is-danger"
                      size="is-large"
                      :disabled="!isAmountToUnstakeValid()" 
                      @click="withdraw"
                      :loading="withdrawing"
                    >
                      Unstake
                    </b-button>
                  </div>
                </div>
              </b-tab-item>
              <b-tab-item label="Claim">
                <p class="content">Claim your rewards from SEV</p>
                <b-field label="Claim EDEN tokens" class="mt-6">
                  <b-input 
                    :disabled="true"
                    :value="formatTokenBalance(earned)"
                    type="text" 
                    size="is-medium"
                    expanded
                  ></b-input>
                </b-field>
                <b-field>
                  <b-checkbox 
                    v-model="checkBox"
                    :disabled="claiming"
                  >Automatically stake</b-checkbox>
                </b-field>
                <b-field label="My new wallet balance" class="mt-4">
                  <p class="is-family-secondary">
                    {{ checkBox ? getNewBalance(tokenBalance, 0, true) : getNewBalance(tokenBalance, earned, true) }} EDEN
                  </p>
                </b-field>
                <b-field label="My new staked balance" class="mt-4">
                  <p>
                    {{ checkBox ? getNewBalance(stakedBalance, earned) : getNewBalance(stakedBalance, 0) }} EDEN
                    <br />
                    <a href="https://explorer.edennetwork.io/" target="_blank">
                      <span>Where will I rank?</span>
                      <span class="icon is-small ml-1">
                        <i class="mdi mdi-arrow-top-right"></i>
                      </span>
                    </a>
                  </p>
                </b-field>
                <div class="columns mt-4">
                  <div class="column">
                    <b-button
                      expanded
                      rounded
                      type="is-success"
                      size="is-large"
                      :disabled="!isAmountToClaimValid()" 
                      @click="claim"
                      :loading="claiming"
                    >
                      {{checkBox ? `Claim and Stake` : `Claim`}}
                    </b-button>
                  </div>
                </div>
              </b-tab-item>
            </b-tabs>
          </div>
        </div>
    </section>
</template>

<script>
  import { mapGetters } from 'vuex';
  import AnimatedNumber from "animated-number-vue";
  import { utils, BigNumber } from "ethers";

  const POLL_RATE = 5 * 1000;
  export default {
    components: {
      AnimatedNumber,
    },
    computed: {
      ...mapGetters({
        account: 'accountModule/account',
        contracts: 'contracts',
        sevFor: 'merkle/sevFor',
        tokenBalances: 'tokenBalances',
      }),
      tokenBalance: function () {
        try {
          const edenAddress = this.contracts.tokenContract.address;
          return this.tokenBalances[edenAddress];
        }
        catch {
          return BigNumber.from("0");
        }
      },
      earned: function () {
        try {
          const earned = this.sevFor(this.account)
          if (earned) {
            return earned
          }
        }
        catch {}
        return BigNumber.from("0")
      },
      sushiUrl: function () {
        try {
          const edenAddress = this.contracts.tokenContract.address;
          const baseUrl = "https://app.sushi.com/swap/ETH/"
          return baseUrl + edenAddress;
        }
        catch {
          return "https://app.sushi.com/swap"
        }
      },
      stakedBalance: function () {
        try {
          return this.stakedBalances['slotMarket'];
        }
        catch {
          return BigNumber.from("0");
        }
      },
    },
    data() {
      return {
        polling: null,
        approved: false,
        approving: false,
        checkBox: true,
        claiming: false,
        staking: false,
        withdrawing: false,
        amountToStake: null,
        amountToUnstake: null,
        activeTab: 0
      };
    },
    mounted() {
      this.handlePolling();
      this.polling = setInterval(this.handlePolling, POLL_RATE);
    },
    props: [
      'approvedBalances',
      'stakedBalances'
    ],
    methods: {
      async handlePolling() {
        await this.$store.dispatch('getTokenBalancesForUser');
        await this.$store.dispatch('getStakedBalancesForUser');
        await this.$store.dispatch('merkle/loadSev');
      },
      approvedBalance() {
        try {
          const edenAddress = this.contracts.tokenContract.address;
          const slotMarketAddress = this.contracts.slotMarketContract.address;
          return this.approvedBalances[edenAddress][slotMarketAddress];
        }
        catch {
          return BigNumber.from("0");
        }
      },
      setMax(staking=true) {
        if (staking) {
          // ignore if max is pressed twice
          if (BigNumber.isBigNumber(this.amountToStake) && this.tokenBalance.eq(this.amountToStake)) {
            return;
          }
          this.amountToStake = this.tokenBalance;
          this.onStakeInput(this.format(this.amountToStake));
        }
        else {
          // ignore if max is pressed twice
          if (BigNumber.isBigNumber(this.amountToUnstake) && this.stakedBalance.eq(this.amountToUnstake)) {
            return;
          }
          this.amountToUnstake = this.stakedBalance;
          this.onUnstakeInput(this.format(this.amountToUnstake));
        }
      },
      onStakeInput(value) {
        this.amountToStake = this.inputToBigNumber(value);
        if (
          this.amountToStake 
          && this.amountToStake.gt("0") 
          && this.approvedBalance().gte(this.amountToStake)
        ) {
          // Account has on-chain approval
          this.approved = true;
        }
        else {
          // No on-chain approval
          this.approved = false;
        }
      },
      onUnstakeInput(value) {
        this.amountToUnstake = this.inputToBigNumber(value);
      },
      format(value) {
        try {
          const decimal = utils.formatEther(value); // BN to string
          const formatted = decimal.replace(/[.]0*$/, '') // Drop trailing zeroes
          return formatted;
        }
        catch {
          return null;
        }
      },
      inputToBigNumber(value) {
        try {
          const validInput = value.match(/^[^\D]*[.]{0,1}[^\D]{0,18}/)[0]; // Match a valid decimal
          return utils.parseEther(validInput); // user input to BN
        }
        catch (err) {
          return null;
        }
      },
      to0DpAndCurrencyFormatted(value) {
        const decimal = this.$options.filters.fromWei(value);
        const formatted = this.$options.filters.currency(decimal);
        return `${formatted}`;
      },
      formatTokenBalance(value) {
        try {
          return `${this.to0DpAndCurrencyFormatted(value)}`;
        }
        catch {
          return `0`;
        }
      },
      getNewBalance(currentValue, amount, additive=true) {
        let newValue;

        if (BigNumber.isBigNumber(currentValue) && BigNumber.isBigNumber(amount)) {
          if (additive) {
            newValue = currentValue.add(amount);
          }
          else if (amount.lt(currentValue)) {
            newValue = currentValue.sub(amount);
          }
        }
        else {
          newValue = currentValue;
        }
        return `${this.to0DpAndCurrencyFormatted(newValue)}`;
      },
      isAmountToStakeValid() {
        try {
          return this.amountToStake.gt("0") && this.amountToStake.lte(this.tokenBalance);
        }
        catch {
          return false;
        }
      },
      isAmountToUnstakeValid() {
        try {
          return this.amountToUnstake.gt("0") && this.amountToUnstake.lte(this.stakedBalance);
        }
        catch {
          return false;
        }
      },
      isAmountToClaimValid() {
        try {
          return this.earned.gt("0")
        }
        catch {
          return false
        }
      },
      async approve() {
        this.approving = true;
        let approveResult;
        try {
          const { tokenContract, slotMarketContract } = this.contracts;
          const payload = {
            token: tokenContract.address,
            amount: this.amountToStake,
            spender: slotMarketContract.address
          }

          approveResult = await this.$store.dispatch('approveSimple', payload);
          if (approveResult) {
            // update
            await this.$store.dispatch('getApprovedBalancesForUser');
          }
        }
        catch {
          approveResult = false;
        }
        this.approving = false;
        this.approved = approveResult;
      },
      async stake() {
        this.staking = true;
        let stakeResult;
        try {
          stakeResult = await this.$store.dispatch('stakeWithPermit', this.amountToStake);
        }
        catch (err) {
          stakeResult = false;
        }
        if (stakeResult) {
          this.amountToStake = null;
          this.onStakeInput(null);
        }
        this.staking = false;
      },
      async withdraw() {
        this.withdrawing = true;
        let withdrawResult;
        try {
          withdrawResult = await this.$store.dispatch('unstake', this.amountToUnstake);
        }
        catch {
          withdrawResult = false;
        }
        if (withdrawResult) {
          this.amountToUnstake = null;
        }
        this.withdrawing = false;
      },
      async claim() {
        this.claiming = true;
        let claimResult;
        try {
          if (this.checkBox) {
            claimResult = await this.$store.dispatch('merkle/claimAndStakeSev');
          }
          else {
            claimResult = await this.$store.dispatch('merkle/claimSev');
          }
        }
        catch {
          claimResult = false;
        }
        if (claimResult) {
          // update
        }
        this.claiming = false;
      }
    },
  };
</script>
