import {ethers} from 'ethers';
import { RPC_URL } from '../settings';

const state = () => ({
    account: null,
    chainId: null,
    provider: null,
    signer: null,
    onboard: null,
    initialLoadComplete: false
})

const getters = {
    account: state => state.account,
    chainId: state => state.chainId
}

const mutations = {
    setAccount(state, account) {
        state.account = account
    },
    setChainId(state, chainId) {
        state.chainId = chainId
    }
}

const actions = {
    log (state) {
        console.log(state)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}