<template>
    <article>

        <div class="hero hero-image-holder">
          <!-- <img class="hero-img" src="/img/hero.jpg" alt=""> -->
          <img class="hero-img back" src="/assets/headers/eden-hero-background.jpg" alt="">
          <img class="hero-img fore" src="/assets/headers/eden-hero-foreground.png" alt="">
          <div class="hero-body">
              <div class="container is-max-desktop">
                  <h1 class="title is-size-1 has-text-success">
                    Staking
                  </h1>
                  <h2 class="subtitle">
                    Stake EDEN to increase your transaction priority
                  </h2>
                  <b-button class="is-primary" tag="a" href="https://docs.edennetwork.io/for-traders/getting-started" target="_blank">
                    Learn More
                  </b-button>
                  <b-button class="ml-2" tag="a" :href="sushiUrl()" target="_blank">
                    Buy EDEN on Sushi
                  </b-button>
              </div>
          </div>
          <div class="hero-footer">
            <div class="sweep-left-blue"></div>
          </div>
        </div>

        <section v-if="account">
            <div class="container is-max-desktop mt-5">
                <section>
                    <voting-power 
                      :approvedBalances="approvedBalances"
                      :stakedBalances="stakedBalances"
                    ></voting-power>
                </section>
            </div>
        </section>

        <section v-else-if="!account">
          <div class="container is-max-desktop">
            <h2>Please connect your wallet</h2>
          </div>
        </section>

        <spinner v-else></spinner>
    </article>
</template>
<script>
  import { mapGetters } from 'vuex';
  
  import Spinner from "../components/Spinner";
  import VotingPower from '../components/VotingPower';

  const POLL_RATE = 15 * 1000;
  export default {
    components: {
      VotingPower,
      Spinner,
    },
    computed: {
      ...mapGetters({
        account: 'accountModule/account',
        approvedBalances: 'approvedBalances',
        contracts: 'contracts',
        stakedBalances: 'stakedBalances', 
      }),
    },
    data() {
      return {
        polling: null,
      };
    },
    mounted() {
      this.handlePolling();
      this.polling = setInterval(this.handlePolling, POLL_RATE);
    },
    beforeDestroy() {
      clearInterval(this.polling);
    },
    methods: {
      sushiUrl() {
        try {
          const edenAddress = this.contracts.tokenContract.address;
          const baseUrl = "https://app.sushi.com/swap/ETH/"
          return baseUrl + edenAddress;
        }
        catch {
          return "https://app.sushi.com/swap"
        }
      },
      async handlePolling() {
        await this.$store.dispatch('merkle/loadAccountState');
        await this.$store.dispatch('getStakedBalancesForUser');
        await this.$store.dispatch('getApprovedBalancesForUser');
      }
    },
  };
</script>
