<template>
    <article>

        <div class="hero hero-image-holder">
          <img class="hero-img farm" src="/assets/headers/Token_green_leaf_2.jpg" alt="">
          <div class="hero-body">
              <div class="container is-max-desktop">
                  <h1 class="title is-size-1 has-text-success">
                    Liquidity Mining
                  </h1>
                  <h2 class="subtitle">
                    Earn EDEN by supplying liquidity on Sushi
                  </h2>
                  <b-button class="is-primary" tag="a" href="https://medium.com/edennetwork/eden-network-launches-liquidity-mining-program-d9cc4bac61f5" target="_blank">
                    Learn More
                  </b-button>
                  <b-button class="ml-2" tag="a" :href="sushiUrl()" target="_blank">
                    Add Liquidity on Sushi
                  </b-button>
              </div>
          </div>
          <div class="hero-footer">
            <div class="sweep-left-blue"></div>
          </div>
        </div>

        <section v-if="account && tokenBalances && stakingTokenList">
            <div class="container is-max-desktop mt-5">
                <section>
                    <div class="columns">
                      <div class="column">
                        <article class="notification is-primary has-text-centered">
                          <p class="title is-1 mb-0" v-if="farmInfo">
                            {{ parseInt(farmInfo.roiPerDay * 365 * 100) }}%
                          </p>
                          <p class="title is-1 mb-0" v-else>
                            -
                          </p>
                          <hr />
                          <p class="content has-text-weight-bold is-uppercase">Current APR</p>
                        </article>
                      </div>
                      <div class="column">
                        <article class="notification is-primary has-text-centered">
                          <p class="title is-1 mb-0">
                              <animated-number
                                :value="stakedBalance().toString()"
                                :duration="600"
                                :formatValue="to0DpAndCurrencyFormatted"
                              />
                          </p>
                          <hr />
                          <p class="content has-text-weight-bold is-uppercase">My Staked Sushi LP Tokens</p>
                        </article>
                      </div>
                      <div class="column">
                        <article class="notification is-primary has-text-centered">
                          <p class="title is-1 mb-0">
                              <animated-number
                                :value="pendingBalance().toString()"
                                :duration="600"
                                :formatValue="to0DpAndCurrencyFormatted"
                              />
                          </p>
                          <hr />
                          <p class="content has-text-weight-bold is-uppercase">My Earned EDEN</p>
                        </article>
                      </div>
                    </div>
                </section>
                <section>
                  <div class="columns">
                    <div class="column">
                      <div class="box">
                        <p class="title is-4">Deposit Sushi LP Tokens</p>
                        <p class="heading">My wallet: <a @click="setMax(true)">{{ format(tokenBalance()) }} SLP</a></p>
                        <b-input 
                          :value="format(amountToDeposit)"
                          :autofocus="true"
                          :disabled="approving || depositing"
                          @input="onDepositInput"
                          placeholder="Amount"
                          type="text" 
                          size="is-medium has-text-right"
                          expanded
                        >
                        </b-input>
                        <div class="columns mt-4">
                          <div class="column">
                            <b-button
                              expanded
                              rounded
                              type="is-primary"
                              size="is-normal"
                              :disabled="isApproveDisabled()" 
                              :loading="approving"
                              @click="approve"
                            >
                                Approve
                            </b-button>
                          </div>
                          <div class="column">
                            <b-button
                              expanded
                              rounded
                              type="is-primary"
                              size="is-normal"
                              :disabled="isDepositDisabled()" 
                              :loading="depositing"
                              @click="deposit"
                            >
                                Deposit
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="box">
                        <p class="title is-4">Withdraw Sushi LP Tokens</p>
                        <p class="heading">My wallet: <a @click="setMax(false)">{{ format(stakedBalance()) }} SLP</a></p>
                        <b-input 
                          :value="format(amountToWithdraw)"
                          :autofocus="false"
                          :disabled="withdrawing || harvesting"
                          @input="onWithdrawInput"
                          placeholder="Amount"
                          type="text" 
                          size="is-medium has-text-right"
                          expanded
                        >
                        </b-input>
                        <div class="columns mt-4">
                          <div class="column">
                            <b-button
                              expanded
                              rounded
                              type="is-primary"
                              size="is-normal"
                              :disabled="isWithdrawDisabled()" 
                              :loading="withdrawing"
                              @click="withdraw"
                            >
                                Withdraw
                            </b-button>
                          </div>
                          <div class="column">
                            <b-button
                              expanded
                              rounded
                              type="is-primary"
                              size="is-normal"
                              :disabled="isHarvestDisabled()"
                              :loading="harvesting"
                              @click="harvest"
                            >
                                Harvest
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="mt-6" v-if="farmInfo">
                  <p class="title is-4">
                    Farm Stats
                  </p>
                  <div class="box">
                    <div class="level">
                      <div class="level-left">
                        <div class="level-item">
                          <p class="title is-5">
                            Total Value Locked
                          </p>
                        </div>
                      </div>
                      <div class="level-right">
                        <div class="level-item">
                          <p class="title is-5">
                            ${{ parseInt(farmInfo.farmUsd) | currency }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="box">
                    <div class="level">
                      <div class="level-left">
                        <div class="level-item">
                          <p class="title is-5">
                            Sushi LP Tokens Locked
                          </p>
                        </div>
                      </div>
                      <div class="level-right">
                        <div class="level-item">
                          <p class="title is-5">
                            {{ to0DpAndCurrencyFormatted(farmInfo.slpStaked) }} SLP
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="box">
                    <div class="level">
                      <div class="level-left">
                        <div class="level-item">
                          <p class="title is-5">
                            Rewards per Block
                          </p>
                        </div>
                      </div>
                      <div class="level-right">
                        <div class="level-item">
                          <p class="title is-5">
                            {{ to0DpAndCurrencyFormatted(farmInfo.rewardsPerBlock) }} EDEN
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="box">
                    <div class="level">
                      <div class="level-left">
                        <div class="level-item">
                          <p class="title is-5">
                            Daily ROI
                          </p>
                        </div>
                      </div>
                      <div class="level-right">
                        <div class="level-item">
                          <p class="title is-5">
                            {{ Math.round(farmInfo.roiPerDay * 100 * 100) / 100 }}%
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
            </div>
        </section>

        <section v-else-if="!account">
          <div class="container is-max-desktop">
            <h2>Please connect your wallet</h2>
          </div>
        </section>

        <spinner v-else></spinner>
    </article>
</template>
<script>
  import { mapGetters } from 'vuex';
  import AnimatedNumber from "animated-number-vue";
  import { ethers, utils, BigNumber } from "ethers";
  
  import Spinner from "../components/Spinner";
  import VotingPower from '../components/VotingPower';

  const POLL_RATE = 15 * 1000;
  export default {
    components: {
      AnimatedNumber,
      VotingPower,
      Spinner,
    },
    computed: {
      ...mapGetters({
        account: 'accountModule/account',
        approvedBalances: 'approvedBalances',
        contracts: 'contracts',
        stakedBalances: 'stakedBalances', 
        tokenBalances: 'tokenBalances', 
        stakingTokenList: 'stakingTokenList'
      }),
    },
    data() {
      return {
        approved: false,
        approving: false,
        farmInfo: null,
        harvesting: false,
        depositing: false,
        withdrawing: false,
        amountToDeposit: null,
        amountToWithdraw: null,
        polling: null,
      };
    },
    mounted() {
      this.handlePolling();
      this.polling = setInterval(this.handlePolling, POLL_RATE);
    },
    beforeDestroy() {
      clearInterval(this.polling);
    },
    methods: {
      sushiUrl() {
        try {
          const edenAddress = this.contracts.tokenContract.address;
          const baseUrl = "https://app.sushi.com/add/ETH/"
          return baseUrl + edenAddress;
        }
        catch {
          return "https://app.sushi.com/pool"
        }
      },
      to0DpAndCurrencyFormatted(value) {
        const decimal = this.$options.filters.fromWei(value);
        let formatted;
        if (decimal > 100) formatted = this.$options.filters.currency(decimal);
        else formatted = this.$options.filters.currency_2(decimal);
        return `${formatted}`;
      },
      tokenBalance() {
        try {
          const SLPAddress = this.contracts.sushiSwapPairContract.address;
          return this.tokenBalances[SLPAddress]
        }
        catch {
          return BigNumber.from("0");
        }
      },
      stakedBalance() {
        try {
          return this.stakedBalances['farmDeposits'];
        }
        catch {
          return BigNumber.from("0");
        }
      },
      pendingBalance() {
        try {
          return this.stakedBalances['farmRewards'];
        }
        catch {
          return BigNumber.from("0");
        }
      },
      approvedBalance() {
        try {
          const SLPAddress = this.contracts.sushiSwapPairContract.address;
          const RewardsManagerAddress = this.contracts.rewardsManagerContract.address;
          return this.approvedBalances[SLPAddress][RewardsManagerAddress];
        }
        catch {}
        return BigNumber.from("0");
      },
      inputToBigNumber(value) {
        try {
          const validInput = value.match(/^[^\D]*[.]{0,1}[^\D]{0,18}/)[0]; // Match a valid decimal
          return utils.parseEther(validInput); // user input to BN
        }
        catch (err) {
          return null;
        }
      },
      onDepositInput(value) {
        this.amountToDeposit = this.inputToBigNumber(value);
        if (
          this.amountToDeposit 
          && this.amountToDeposit.gt("0") 
          && this.approvedBalance().gte(this.amountToDeposit)
        ) {
          // Account has on-chain approval
          this.approved = true;
        }
        else {
          // No on-chain approval
          this.approved = false;
        }
      },
      onWithdrawInput(value) {
        this.amountToWithdraw = this.inputToBigNumber(value);
      },
      setMax(staking=true) {
        if (staking) {
          // ignore if max is pressed twice
          if (BigNumber.isBigNumber(this.amountToDeposit) && this.tokenBalance().eq(this.amountToDeposit)) {
            return;
          }
          this.amountToDeposit = this.tokenBalance();
          this.onDepositInput(this.format(this.amountToDeposit));
        }
        else {
          // ignore if max is pressed twice
          if (BigNumber.isBigNumber(this.amountToWithdraw) && this.stakedBalance().eq(this.amountToWithdraw)) {
            return;
          }
          this.amountToWithdraw = this.stakedBalance();
          // this.onUnstakeInput(this.format(this.amountToWithdraw));
        }
      },
      async approve() {
        this.approving = true;
        let approveResult;
        try {
          const payload = {
            token: this.contracts.sushiSwapPairContract.address,
            amount: ethers.constants.MaxUint256,
            spender: this.contracts.rewardsManagerContract.address
          }
          approveResult = await this.$store.dispatch('approveSimple', payload);
          if (approveResult) {
            // update
            await this.$store.dispatch('getApprovedBalancesForUser');
          }
        } catch {
          approveResult = false;
        }

        this.approving = false;
        this.approved = approveResult;
      },
      async deposit() {
        this.depositing = true;
        let depositResult;
        try {
          depositResult = await this.$store.dispatch('depositSimple', this.amountToDeposit);
          if (depositResult) {
            // update
            this.amountToDeposit = null;
            this.onDepositInput(null);
            this.$store.dispatch('getTokenBalancesForUser');
            this.$store.dispatch('getStakedBalancesForUser');
          }
        }
        catch {
          depositResult = false;
        }
        this.depositing = false;
      },
      async withdraw() {
        this.withdrawing = true;
        let withdrawResult;
        try {
          withdrawResult = await this.$store.dispatch('withdrawSimple', this.amountToWithdraw);
          if (withdrawResult) {
            // update
            this.amountToWithdraw = null;
            this.onWithdrawInput(null);
            this.$store.dispatch('getTokenBalancesForUser');
            this.$store.dispatch('getStakedBalancesForUser');
          }
        }
        catch {
          withdrawResult = false;
        }

        this.withdrawing = false;
      },
      async harvest() {
        this.harvesting = true;
        let harvestResult;
        try {
          harvestResult = await this.$store.dispatch('depositSimple', ethers.utils.parseUnits("0"));
          if (harvestResult) {
            // update
            this.$store.dispatch('getTokenBalancesForUser');
            this.$store.dispatch('getStakedBalancesForUser');
          }
        }
        catch {
          harvestResult = false;
        }
        this.harvesting = false;
      },
      isApproveDisabled() {
        if (this.approved) return true;
        try {
          if (this.amountToDeposit.gt("0") && this.amountToDeposit.lte(this.tokenBalance())) return false;
        }
        catch {}
        return true;
      },
      isDepositDisabled() {
        if (!this.approved) return true;
        return false;
      },
      isHarvestDisabled() {
        if (this.pendingBalance().eq("0")) return true;
        try {
          if (this.amountToWithdraw.gt("0")) return true;
        }
        catch {}
        return false;
      },
      isWithdrawDisabled() {
        if (this.stakedBalance().eq("0")) return true;
        try {
          if (this.amountToWithdraw.gt("0") && this.amountToWithdraw.lte(this.stakedBalance())) return false;
        }
        catch {}
        return true;
      },
      format(value) {
        try {
          const decimal = utils.formatEther(value); // BN to string
          const formatted = decimal.replace(/[.]0*$/, '') // Drop trailing zeroes
          return formatted;
        }
        catch {
          return null;
        }
      },
      async handlePolling() {
        await this.$store.dispatch('getTokenBalancesForUser');
        await this.$store.dispatch('getStakedBalancesForUser');
        await this.$store.dispatch('getApprovedBalancesForUser');
        this.farmInfo = await this.$store.dispatch('getFarmInfo');
      },
      async getStakingTokenList() {
        await this.$store.dispatch('getStakingTokenList');
      }
    },
  };
</script>
